import React from "react";
import { useTheme } from "@emotion/react";
import { LoadingButton } from "@mui/lab";
import {
  Grid,
  Paper,
  Stack,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  ButtonGroup,
  Divider,
  Collapse,
  Tooltip,
  Slide,
  Box,
  Alert,
  AlertTitle,
  CircularProgress,
  DialogContent,
  useMediaQuery,
  CardActionArea,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  Chip,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import AsyncAutoComplete from "../../../Components/common/AsyncAutoComplete";

import AddIcon from "@mui/icons-material/Add";
import Add from "@mui/icons-material/Add";

import RemoveIcon from "@mui/icons-material/Remove";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import Create from "@mui/icons-material/Create";
import AddBoxIcon from "@mui/icons-material/AddBox";

import { getSuppliers, queryOrder, checkPoNumber } from "../../API/Cheques&Pos";
import { getExchangeRate } from "../../API/Sales";
import AddSupplierForm from "../Forms/AddSupplier";
import config from "../../Config";
import NumberFormat from "../../../Components/common/NumberFormater";
import PosItemArea from "./PosItemArea";
import UniversalTable from "../../../Components/common/UniversalTable";
import ChequeArea from "./ChequeArea";

import ManualDetailsForm from "../Forms/ManualDetails";

import { TransitionGroup } from "react-transition-group";

import { toast } from "react-toastify";
import ConfirmationDialog from "./ConfirmDialog";
import { OrderSummary, OrderCard } from "./OrderSummary";
import {
  ArrowRight,
  Calculate,
  CheckCircle,
  CheckCircleOutline,
  Today,
  Unpublished,
} from "@mui/icons-material";
import DateTimePicker from "./DateTimePicker";

export default function Main({
  onComplete,
  onSubmit,
  data,
  title,
  edit,
  setChanged,
  loadingEditData,
}) {
  const deepCopyItems = React.useCallback((target) => {
    const copy = target.map((poObject) => ({
      orderID: poObject?.orderID,
      items: poObject?.items?.map((item) => ({ ...item })),
    }));
    return copy;
  }, []);

  const [poNumber, setPoNumber] = React.useState(data?.poNumber || 0),
    [poDate, setPoDate] = React.useState(data?.date || Date.now()),
    [supplier, setSupplier] = React.useState(data?.defaultSupplier || ""),
    [withholding, setWithholding] = React.useState(data?.withholding ?? true),
    [currency, setCurrency] = React.useState(data?.currency || "KES"),
    [amount, setAmount] = React.useState(data?.amount || 0),
    [creating, setCreating] = React.useState(false),
    [validNumber, setValidNumber] = React.useState(false),
    [note, setNote] = React.useState(data?.note || "");
  const [noNumber, setNoNumber] = React.useState(edit ? true : false);

  const [cheques, setCheques] = React.useState(
    data?.cheques.map((cheque) => ({ ...cheque })) || []
  );
  const [exchangeRates, setExchangeRates] = React.useState({
    USD: { rate: 1 },
    KES: { rate: 1 },
  });

  const [orderIdList, setOrderIdList] = React.useState(data?.orderIDs || []),
    [orderList, setOrderList] = React.useState(data?.order_list || []);

  const [originalItems, setOriginalItems] = React.useState(
      deepCopyItems(data?.originalItems || [])
    ),
    [pendingItems, setPendingItems] = React.useState(
      deepCopyItems(data?.pendingItems || [])
    ),
    [poItems, setPoItems] = React.useState(deepCopyItems(data?.poItems || []));

  const [invalid, setInvalid] = React.useState(true);

  const switchWithholding = () => setWithholding(!withholding);
  const [show, setShow] = React.useState(false);
  const [saveData, setSaveData] = React.useState("");

  const switchCurrency = (e) => setCurrency(e.target.value);

  //update poItems and pending whenever data changes
  React.useEffect(() => {
    setPoItems(deepCopyItems(data?.poItems || []));
    setPendingItems(deepCopyItems(data?.pendingItems || []));
    setCheques(data?.cheques.map((cheque) => ({ ...cheque })) || []);
  }, [data, deepCopyItems]);

  //checking is an purchase order can be recovered
  React.useEffect(() => {
    const save = JSON.stringify({
      poNumber,
      supplier,
      withholding,
      currency,
      amount,
      note,
      cheques,
      orderIdList,
      orderList,
      originalItems,
      pendingItems,
      poItems,
      noNumber,
    });

    const local = localStorage.getItem("poData");

    if (local !== null && !edit && local !== save) {
      setShow(true);
      setSaveData(JSON.parse(localStorage.getItem("poData")));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const restoreData = () => {
    setPoNumber(saveData.poNumber);
    setSupplier(saveData.supplier);
    setCurrency(saveData.currency);
    setAmount(saveData.amount);
    setNote(saveData.note);
    setCheques(saveData.cheques);
    setOrderIdList(saveData.orderIdList);
    setOrderList(saveData.orderList);
    setOriginalItems(saveData.originalItems);
    setPendingItems(saveData.pendingItems);
    setPoItems(saveData.poItems);
    setWithholding(saveData.withholding);
    setNoNumber(saveData.noNumber);
  };

  // for saving values
  React.useEffect(() => {
    if (!edit) {
      const save = JSON.stringify({
        poNumber,
        supplier,
        withholding,
        currency,
        amount,
        note,
        cheques,
        orderIdList,
        orderList,
        originalItems,
        pendingItems,
        poItems,
        noNumber,
      });

      localStorage.setItem("poData", save);
    }
  }, [
    amount,
    cheques,
    currency,
    edit,
    exchangeRates,
    note,
    orderIdList,
    orderList,
    originalItems,
    pendingItems,
    poItems,
    poNumber,
    supplier,
    withholding,
    noNumber,
  ]);

  const [checkingValidNumber, setCheckingValidNumber] = React.useState(false);

  React.useEffect(() => {
    let validate;

    setValidNumber(false);

    if (poNumber === data?.poNumber || edit) {
      setValidNumber(true);
      return;
    }
    const checkValid = () => {
      setCheckingValidNumber(true);
      checkPoNumber({ id: poNumber })
        .then((res) => {
          setValidNumber(res);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setCheckingValidNumber(false);
        });
    };

    if (poNumber.length >= 5) {
      validate = setTimeout(checkValid, 400);
    }

    return () => {
      clearTimeout(validate);
    };
  }, [poNumber, data, edit]);

  React.useEffect(() => {
    // make sure cheque data is valid
    const validateCheques = () => {
      let valid = true;
      const uniqueNumbers = [];

      if (cheques.length !== 0) {
        cheques.forEach((cheque) => {
          valid =
            valid &&
            cheque.number !== "" &&
            cheque.valid &&
            !uniqueNumbers.includes(cheque.number);
          uniqueNumbers.push(cheque.number);
        });
      }

      return valid;
    };
    // check if there has been a change in cheque
    const chequeChange = () => {
      let valid = false;
      if (cheques.length === 0) {
        return false;
      }
      if (cheques.length !== data?.cheques.length) {
        return true;
      }
      cheques.forEach((cheque, index) => {
        valid =
          valid ||
          cheque.cancelled !== data?.cheques[index]?.cancelled ||
          cheque.number !== data?.cheques[index]?.number ||
          Number(cheque.amount) !== Number(data?.cheques[index]?.amount) ||
          cheque.bank !== data?.cheques[index]?.bank;
      });
      return valid;
    };
    const validatePoItems = () => {
      let valid = true;

      if (poItems.length !== 0) {
        poItems.forEach((object) => {
          const itemList = object.items;
          valid = valid && itemList.length !== 0;
        });
      }

      return valid;
    };

    const poItemsChange = () => {
      let change = false;

      const itemsArray = poItems.map((object) => object.items);

      itemsArray.forEach((list, indexA) => {
        const previousList = data?.poItems[indexA].items.map((poItem) => ({
          ...poItem,
        }));
        if (previousList.length !== list.length) {
          change = true;
          return;
        }
        list.forEach((item, indexB) => {
          change =
            change ||
            item.partNumber !== previousList[indexB]?.partNumber ||
            item.description !== previousList[indexB]?.description ||
            Number(item.quantity) !== Number(previousList[indexB]?.quantity) ||
            Number(item.price) !== Number(previousList[indexB]?.price);
        });
      });

      return change;
    };

    const validateOriginalItems = () => {
      let valid = true;
      if (originalItems.length !== 0) {
        originalItems.forEach((object) => {
          object.items.forEach((item) => {
            const orderID = object.orderID;
            const items = poItems.find(
              (object) => object.orderID === orderID
            ).items;
            const hasPos = items.findIndex(
              (poItem) => poItem.itemID === item.itemID
            );
            valid = valid && hasPos !== -1;
          });
        });
      }

      return valid;
    };

    const checkOrderChange = () => {
      let change = false;

      if (data?.orderIDs?.length !== orderIdList.length) {
        return true;
      }
      orderIdList.forEach((id, index) => {
        change = change || id !== data?.orderIDs[index];
      });
      return change;
    };

    const pendingChange = () => {
      let change = false;

      const itemsArray = pendingItems.map((object) => object.items);

      itemsArray.forEach((list, indexA) => {
        const previousList = data?.pendingItems[indexA].items.map(
          (pending) => ({
            ...pending,
          })
        );
        list.forEach((item, indexB) => {
          change =
            change ||
            item.description !== previousList[indexB]?.description ||
            Number(item.quantity) !== Number(previousList[indexB]?.quantity) ||
            item.deleted === true;
        });
      });

      return change;
    };

    // check for changes in data
    const changed =
      new Date(poDate).toString() !== new Date(data?.date).toString() ||
      data?.withholding !== withholding ||
      data?.currency !== currency ||
      Number(data?.amount) !== Number(amount) ||
      data?.note !== note ||
      supplier?.id !== data?.defaultSupplier?.id ||
      checkOrderChange() ||
      chequeChange() ||
      poItemsChange() ||
      pendingChange();

    if (edit) {
      setChanged(changed);
    }

    if (
      ((String(poNumber).length >= 5 && validNumber) || edit || noNumber) &&
      supplier &&
      supplier !== "" &&
      amount !== "" &&
      amount > 0 &&
      validatePoItems() &&
      poItems.length !== 0 &&
      validateCheques() &&
      validateOriginalItems() &&
      changed
    ) {
      setInvalid(false);
      return;
    }
    setInvalid(true);
  }, [
    poNumber,
    validNumber,
    poItems,
    cheques,
    supplier,
    amount,
    originalItems,
    edit,
    noNumber,
    data,
    poDate,
    withholding,
    currency,
    note,
    setChanged,
    orderIdList,
    pendingItems,
  ]);

  const calculateWithholdings = React.useMemo(() => {
    let calc = 0;
    if (withholding) calc = (amount / config.VAT) * config.withHoldingTax;
    return calc;
  }, [amount, withholding]);

  const calculateFinalAmount = React.useMemo(() => {
    return amount - calculateWithholdings;
  }, [amount, calculateWithholdings]);

  const flattenItems = (array) => {
    let newArray = [];
    const cleanedItems = array.map((object) => object.items);

    for (let arrays of cleanedItems) {
      newArray = [...newArray, ...arrays];
    }
    return newArray;
  };

  React.useEffect(() => {
    getExchangeRate().then((res) => {
      setExchangeRates({ USD: res[0], KES: res[2] });
    });
  }, []);

  const handleSubmit = () => {
    const postData = {
      cheques: cheques.filter((cheque) => cheque.cancelled !== true),
      poNumber: noNumber && !edit ? "" : poNumber,
      orders: orderIdList,
      poDate: new Date(poDate).toLocaleString("en-GB", { timeZone: "UTC" }),
      supplier: supplier?.id || data?.supplier,
      amount: amount,
      withholding: withholding,
      withholdingAmount: calculateWithholdings,
      finalAmount: calculateFinalAmount,
      currency: currency,
      note: note,
      poItems: poItems,
      pendingItems: flattenItems(pendingItems),
      originalPo: data?.poNumber,
    };

    setCreating(true);

    onSubmit({ po: JSON.stringify(postData) })
      .then((res) => {
        if (res.status === "success") {
          toast.success(res.message);
          onComplete(res.id);
        } else {
          toast.warning(res.message);
        }
      })
      .catch((err) => {
        toast.error(
          "Error processing POS, please try again. if the issues persists contact an admin"
        );
        console.log(err);
      })
      .finally(() => {
        setCreating(false);
      });
  };

  const bottomAreaStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    position: "fixed",
    bottom: 10,
    right: 0,
    mr: 4,
    width: "fit-content",
    zIndex: 1301,
    marginBottom: edit && 8,
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [sidePanel, setSidePanel] = React.useState(
    localStorage.getItem("POSsidePanel") === "true" || false
  );

  const handleSidePanel = () => {
    setSidePanel((prev) => {
      localStorage.setItem("POSsidePanel", !sidePanel);
      return !prev;
    });
  };

  const SidePanelDetail = ({
    name,
    valid,
    value,
    isList = false,
    valueName,
  }) => (
    <ListItem>
      <ListItemIcon>
        {valid ? (
          <CheckCircleOutline color="success" />
        ) : (
          <ClearIcon color="error" />
        )}
      </ListItemIcon>
      <ListItemText
        primary={name}
        secondary={
          <>
            {isList ? (
              <List dense>
                {value.map((item, index) => (
                  <ListItem key={`${new Date()}_${index}`}>
                    <ListItemText
                      primary={
                        item[valueName].length > 12
                          ? item[valueName].slice(0, 12) + "..."
                          : item[valueName]
                      }
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              valid && value && value
            )}
          </>
        }
        disableTypography={isList}
      />
    </ListItem>
  );

  const poItemsTotal = () => {
    let total = 0;

    poItems.forEach((object) => {
      const itemList = object.items;
      itemList.forEach((item) => {
        if (!item?.storeID) total += Number(item.price) * Number(item.quantity);
      });
    });

    if (withholding) total = total * config.VAT;

    return total;
  };

  const validatePoItems = () => {
    let valid = true;

    if (poItems.length !== 0) {
      poItems.forEach((object) => {
        const itemList = object.items;
        valid = valid && itemList.length !== 0;
      });
    }

    return valid;
  };

  // make sure cheque data is valid
  const validateCheques = () => {
    let valid = true;
    const uniqueNumbers = [];

    if (cheques.length !== 0) {
      cheques.forEach((cheque) => {
        valid =
          valid &&
          cheque.number !== "" &&
          cheque.valid &&
          !uniqueNumbers.includes(cheque.number);
        uniqueNumbers.push(cheque.number);
      });
    }

    return valid;
  };

  const poCalcAmount = React.useMemo(() => {
    let total = 0;
    data?.poItems.forEach((order) => {
      order.items.forEach((item) => {
        if (!item.isStore) total += item.price * item.quantity;
      });
    });

    if (data?.withholding) return total * config.VAT;
    return total;
  }, [data]);

  const poCalcToCheque = React.useMemo(() => {
    if (data?.withholding)
      return poCalcAmount - (poCalcAmount / config.VAT) * config.withHoldingTax;
    return poCalcAmount * config.VAT;
  }, [poCalcAmount, data]);

  return (
    <>
      <Dialog
        open={show}
        onClose={() => setShow(false)}
        PaperProps={{ elevation: 0 }}
      >
        <ConfirmationDialog
          title="Recover Purchase Order"
          body="An incomplete Form data was recovered. Do you want to restore it?"
          onClose={() => setShow(false)}
          onDelete={() => {
            setShow(false);
            restoreData();
          }}
        />
      </Dialog>
      <DialogTitle>{title}</DialogTitle>
      {!isMobile && (
        <CardActionArea
          onClick={handleSidePanel}
          sx={{
            height: "80vh",
            borderRadius: "0px 8px 8px 0px",
            border: (theme) => `1px solid ${theme.palette.divider}`,
            bgcolor: (theme) => theme.palette.background.default,
            transition: (theme) => theme.transitions.create("width"),
            easing: (theme) => theme.transitions.easing.easeInOut,
            duration: (theme) => theme.transitions.duration.complex,
            position: "fixed",
            bottom: 0,
            top: 40,
            my: "auto",
            width: sidePanel ? 250 : 20,
            display: "flex",
            alignItems: sidePanel ? "flex-start" : "center",
            justifyContent: sidePanel ? "flex-start" : "center",
            overflowX: "hidden",
          }}
        >
          <Collapse in={!sidePanel} orientation="horizontal">
            <ArrowRight />
          </Collapse>
          <Collapse in={sidePanel} sx={{ width: "100%" }}>
            <List dense>
              <SidePanelDetail
                name="PO Number"
                valid={validNumber || noNumber}
                value={noNumber ? "No PO Number" : poNumber}
              />
              <SidePanelDetail
                name="Supplier"
                valid={supplier || supplier !== ""}
                value={supplier?.name}
              />
              <SidePanelDetail
                name="Amount"
                valid={amount > 0}
                value={`${currency} ${NumberFormat(amount)} ${
                  withholding ? "VAT Included" : ""
                }`}
              />
              <ListItem>
                <ListItemIcon>
                  <Calculate color="info" />
                </ListItemIcon>
                <ListItemText
                  primary={"Calculated Amount"}
                  secondary={NumberFormat(poItemsTotal().toFixed(2))}
                />
              </ListItem>
              <SidePanelDetail
                name="Orders"
                valid={poItems.length > 0}
                value={orderList}
                valueName="orderID"
                isList
              />
              <SidePanelDetail
                name="Items"
                valid={poItems.length > 0 && validatePoItems()}
              />
            </List>
            <Divider textAlign="left">
              <Typography variant="overline">optional</Typography>
            </Divider>
            <List>
              <SidePanelDetail
                name="Cheques"
                valid={cheques.length > 0 && validateCheques()}
                value={cheques}
                valueName={"number"}
                isList
              />
            </List>
          </Collapse>
        </CardActionArea>
      )}
      <DialogContent dividers>
        <Box
          sx={{
            transition: (theme) => theme.transitions.create("width"),
            easing: (theme) => theme.transitions.easing.easeInOut,
            duration: 70,
            ml: !isMobile && sidePanel ? 30 : 2,
          }}
        >
          <CollapseArea title="Main Details">
            <MainDetails
              setPoDate={setPoDate}
              poDate={poDate}
              setPoNumber={setPoNumber}
              poNumber={poNumber}
              setSupplier={setSupplier}
              supplier={supplier}
              setNote={setNote}
              note={note}
              setAmount={setAmount}
              amount={amount}
              calculateWithholdings={calculateWithholdings}
              calculateFinalAmount={calculateFinalAmount}
              switchWithholding={switchWithholding}
              withholding={withholding}
              switchCurrency={switchCurrency}
              currency={currency}
              validNumber={validNumber}
              checkingValidNumber={checkingValidNumber}
              data={data}
              edit={edit}
              noNumber={noNumber}
              setNoNumber={setNoNumber}
            />
          </CollapseArea>
          <Divider />
          <CollapseArea title="Cheques">
            <Typography variant="h6" color="primary" sx={{ my: 1 }}>
              Cheque
            </Typography>
            <Stack spacing={2} direction="row" sx={{ mb: 2 }}>
              <TextField label="PO Number" value={poNumber} disabled />
              <TextField label="Amount" value={calculateFinalAmount} disabled />
            </Stack>
            <Stack
              direction="column"
              spacing={2}
              alignItems="flex-start"
              sx={{ pl: 2, mb: 2 }}
            >
              <Chip
                label={`Item Costs: ${NumberFormat(poCalcAmount.toFixed(2))}${
                  data?.withholding ? " (VAT Included)" : ""
                }`}
                color={"info"}
              />
              <Chip
                label={`Cheque Amount: ${NumberFormat(
                  poCalcToCheque.toFixed(2)
                )}${
                  poCalcToCheque.toFixed(2) === calculateFinalAmount.toFixed(2)
                    ? ""
                    : " ≠ " + NumberFormat(calculateFinalAmount.toFixed(2))
                }`}
                color={
                  poCalcToCheque.toFixed(2) === calculateFinalAmount.toFixed(2)
                    ? "success"
                    : "error"
                }
                icon={
                  poCalcToCheque.toFixed(2) ===
                  calculateFinalAmount.toFixed(2) ? (
                    <CheckCircle />
                  ) : (
                    <Unpublished />
                  )
                }
              />
            </Stack>
            <ChequeArea
              cheques={cheques}
              setCheques={setCheques}
              amount={calculateFinalAmount}
              edit={edit}
              currency={currency}
              isCashCheque={
                supplier.name === "cash" || supplier.name === "Cash"
              }
              poNumber={poNumber}
            />
          </CollapseArea>
          <Divider />
          <CollapseArea title="Add Orders">
            <Typography variant="h6" color="primary" sx={{ my: 1 }}>
              Add Orders
            </Typography>

            <ClientArea
              orderIdList={orderIdList}
              setOrderIdList={setOrderIdList}
              setOrderList={setOrderList}
              setItems={setOriginalItems}
              setPoItems={setPoItems}
              setPendingItems={setPendingItems}
              currency={currency}
            />
          </CollapseArea>
          <CollapseArea title="Items">
            <Paper
              elevation={0}
              sx={{
                mx: -2,
                p: 2,
              }}
            >
              <Typography variant="h6" color="primary" sx={{ my: 1 }}>
                POS Items
              </Typography>

              <PosItemsArea
                orders={orderList}
                setOrders={setOrderList}
                poItems={poItems}
                setPoItems={setPoItems}
                orderItems={originalItems}
                setOrderItems={setOriginalItems}
                setOrderIdList={setOrderIdList}
                pendingItems={pendingItems}
                setPendingItems={setPendingItems}
                currency={currency}
                setExchangeRates={setExchangeRates}
                exchangeRates={exchangeRates}
              />
            </Paper>
          </CollapseArea>
        </Box>

        <Slide direction="up" in={invalid} mountOnEnter unmountOnExit>
          <Box sx={bottomAreaStyle}>
            <Alert severity="warning">
              <AlertTitle>Missing Details</AlertTitle>
            </Alert>
          </Box>
        </Slide>
        <Slide direction="up" in={!invalid}>
          <Box sx={bottomAreaStyle}>
            <LoadingButton
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              size="large"
              sx={{
                width: "fit-content",
              }}
              startIcon={edit ? <Create /> : <AddBoxIcon />}
              loading={creating || loadingEditData}
            >
              {`${edit ? "Edit" : "Create"} Purchase Order`}
            </LoadingButton>
          </Box>
        </Slide>
      </DialogContent>
    </>
  );
}

function SupplierList({ value, setChange, SupplierID, data }) {
  const [addSupplier, setAddSupplier] = React.useState(false);

  const closeAddSupplier = () => {
    setAddSupplier(false);
  };
  const completeAddSupplier = (data) => {
    setChange({ id: data.id, name: data.name });
    setAddSupplier(false);
  };

  return (
    <Stack spacing={2} sx={{ my: 1 }}>
      <AsyncAutoComplete
        getData={getSuppliers}
        setValue={setChange}
        label="Supplier"
        optionLabel={"name"}
        defaultOption={data?.defaultSupplier?.name || value?.name}
      />
      <Button
        variant="outlined"
        color="primary"
        startIcon={<AddIcon />}
        sx={{ maxWidth: "fit-content", marginRight: 2 }}
        onClick={() => setAddSupplier(true)}
      >
        Add new supplier
      </Button>

      <Dialog open={addSupplier} onClose={closeAddSupplier}>
        <DialogTitle>Add new supplier</DialogTitle>
        <AddSupplierForm
          onClose={closeAddSupplier}
          onComplete={completeAddSupplier}
        />
      </Dialog>
    </Stack>
  );
}

function CollapseArea({ title, children }) {
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();

  return (
    <Stack>
      <Collapse in={open}>{children}</Collapse>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => setOpen(!open)}
        sx={{
          my: 1,
          width: "fit-content",
          whiteSpace: "nowrap",
          ml: open ? "" : "calc(99%)",
          transform: open ? "" : "translateX(-99%)",
          transition: theme.transitions.create("margin", {
            duration: theme.transitions.duration.complex,
          }),
        }}
        startIcon={
          <ExpandMoreIcon
            sx={{
              transform: !open ? "rotate(0deg)" : "rotate(180deg)",
              transition: theme.transitions.create("transform", {
                duration: theme.transitions.duration.complex,
              }),
            }}
          />
        }
      >
        {open ? "Hide" : "Show "} {title}
      </Button>
    </Stack>
  );
}

function MainDetails({
  setPoDate,
  poDate,
  setPoNumber,
  poNumber,
  setSupplier,
  supplier,
  setNote,
  note,
  amount,
  setAmount,
  calculateWithholdings,
  calculateFinalAmount,
  switchWithholding,
  withholding,
  switchCurrency,
  currency,
  validNumber,
  checkingValidNumber,
  data,
  edit,
  noNumber,
  setNoNumber,
}) {
  const [poNumberHolder, setPoHolder] = React.useState(poNumber);
  const [noteHolder, setNoteHolder] = React.useState(note);
  const [amountHolder, setAmountHolder] = React.useState(amount);

  const handleChange = () => {
    setNoNumber((previous) => !previous);
  };

  React.useEffect(() => {
    setNoteHolder(note);
    setPoHolder(poNumber);
    setAmountHolder(amount);
  }, [amount, note, poNumber]);

  React.useEffect(() => {
    const editValues = () => {
      setPoNumber(poNumberHolder);
      setNote(noteHolder);
      setAmount(amountHolder);
    };

    const editField = setTimeout(editValues, 300);
    return () => {
      clearTimeout(editField);
    };
  }, [
    poNumberHolder,
    noteHolder,
    amountHolder,
    setNote,
    setPoNumber,
    setAmount,
  ]);

  const [POText, setPOText] = React.useState("Must be Unique 5+ digit number");

  React.useEffect(() => {
    if (poNumber.length < 5) {
      setPOText("Must be Unique 5+ digit number");
    } else {
      if (validNumber) {
        setPOText("");
      } else {
        setPOText("This PO already exists");
      }
    }
  }, [poNumber, validNumber]);

  const [checkingIndicator, setCheckingIndicator] = React.useState(false);
  const [checkComplete, setCheckComplete] = React.useState(false);

  React.useEffect(() => {
    if (checkingValidNumber) {
      setCheckComplete(false);
      setCheckingIndicator(true);
    } else {
      setTimeout(() => {
        setCheckComplete(true);
      }, 1500);
      setTimeout(() => {
        setCheckingIndicator(false);
      }, 2000);
    }
  }, [checkingValidNumber]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} p={2}>
        <Typography variant="h6" color="primary" sx={{ mb: 1 }}>
          Details
        </Typography>
        <Stack spacing={2} direction="column">
          <DateTimePicker value={poDate} setChange={(e) => setPoDate(e)} />
          <Collapse in={edit || !noNumber}>
            <TextField
              label="PO Number"
              error={
                (poNumber === "" || validNumber === false) && !edit && !noNumber
              }
              helperText={
                (poNumber.length < 5 || validNumber === false) &&
                !edit &&
                !noNumber &&
                POText
              }
              type={edit ? "text" : "number"}
              value={poNumberHolder}
              onChange={(e) => setPoHolder(e.target.value)}
              required
              disabled={edit}
              onClick={(e) => e.target.select()}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Collapse
                      in={checkingIndicator}
                      orientation="horizontal"
                      timeout={{
                        appear: 200,
                        enter: 200,
                        exit: 700,
                      }}
                      unmountOnExit
                    >
                      {checkComplete ? (
                        <CheckCircleOutline color="success" />
                      ) : (
                        <CircularProgress size={"1rem"} />
                      )}
                    </Collapse>
                  </InputAdornment>
                ),
              }}
            />
          </Collapse>
          {!edit && (
            <Button
              variant={noNumber ? "contained" : "outlined"}
              color="warning"
              onClick={handleChange}
              sx={{ alignSelf: "flex-end" }}
            >
              {noNumber ? "Add PO Number" : "No PO Number"}
            </Button>
          )}
          <SupplierList
            value={supplier}
            setChange={setSupplier}
            SupplierID={""}
            data={data}
          />
          <TextField
            label="Note"
            value={noteHolder}
            onChange={(e) => setNoteHolder(e.target.value)}
            multiline
            rows={5}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={6} p={2}>
        <Typography variant="h6" color="primary" sx={{ mb: 1 }}>
          Amount
        </Typography>
        <Stack spacing={2} direction="column">
          <TextField
            label="Amount"
            error={amount === "" || amount <= 0}
            helperText={
              (amount === "" || amount <= 0) &&
              "Enter a valid number Greater than 0"
            }
            type="number"
            onWheel={(e) => e.target.blur()}
            inputProps={{ min: 0 }}
            value={amountHolder}
            onChange={(e) => setAmountHolder(e.target.value)}
            onClick={(e) => e.target.select()}
            required
          />
          <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
            <ButtonGroup>
              <Button
                onClick={switchWithholding}
                variant={withholding ? "contained" : "outlined"}
                color={withholding ? "primary" : "secondary"}
              >
                Withholding
              </Button>
              <Button
                onClick={switchWithholding}
                variant={!withholding ? "contained" : "outlined"}
                color={!withholding ? "primary" : "secondary"}
              >
                No Withholding
              </Button>
            </ButtonGroup>
            <TextField
              label="Witholding"
              value={NumberFormat(calculateWithholdings.toFixed(2))}
              disabled
              size="small"
              sx={{ width: "fit-content" }}
            />
          </Stack>
          <ButtonGroup>
            <Button
              onClick={switchCurrency}
              value="KES"
              variant={currency === "KES" ? "contained" : "outlined"}
              color={currency === "KES" ? "primary" : "secondary"}
            >
              KES
            </Button>
            <Button
              onClick={switchCurrency}
              value="USD"
              variant={currency === "USD" ? "contained" : "outlined"}
              color={currency === "USD" ? "primary" : "secondary"}
            >
              USD
            </Button>
          </ButtonGroup>
          <TextField
            label="Final PO Amount"
            value={NumberFormat(calculateFinalAmount.toFixed(2))}
            InputProps={{
              startAdornment: (
                <>
                  <Typography>{currency}</Typography>
                  <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                </>
              ),
            }}
            disabled
          />
        </Stack>
      </Grid>
    </Grid>
  );
}

function SearchOrder({ addOrder }) {
  //Table components
  function AddButton({ values }) {
    return (
      <Button
        variant="contained"
        color="success"
        startIcon={<Add />}
        onClick={() => {
          addOrder(values);
          setSearchVal("");
        }}
      >
        Add
      </Button>
    );
  }
  // subtabledata
  const items = [
    {
      id: "partNumber",
      label: "Part Number",
    },
    {
      id: "description",
      label: "Description",
    },
    {
      id: "quantity",
      label: "Quantity",
    },
    {
      id: "dprice",
      label: "Unit Price",
    },
  ];
  const status = [
    {
      id: "date",
      label: "Date",
    },
    {
      id: "status",
      label: "Status",
    },
    {
      id: "note",
      label: "Details",
    },
  ];
  // main table data
  const headers = [
    {
      id: "status",
      subRow: true,
      label: "",
      iconColor: "success",
      headers: status,
      subTitle: "Status",
    },
    {
      id: "items",
      subRow: true,
      label: "",
      iconColor: "info",
      headers: items,
      subTitle: "Items",
    },
    {
      id: "orderID",
      label: "Order Number",
      searchable: true,
    },
    {
      id: "company_name",
      label: "Company",
      searchable: true,
    },
    {
      id: "date_created",
      label: "Date Created",
      searchable: true,
    },
    {
      id: "total",
      label: "Total",
      searchable: true,
    },
    {
      id: "add",
      label: "",
      component: (values) => <AddButton values={values} />,
    },
  ];

  const [searchVal, setSearchVal] = React.useState(""),
    [loading, setLoading] = React.useState(false),
    [loadingVal, setLoadingVal] = React.useState(false),
    [foundList, setFoundList] = React.useState([]);

  const delay = 500;

  const setDefault = () => {
    setSearchVal("");
  };

  const [useCoName, setUseCoName] = React.useState(true);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchVal) {
        const term = searchVal.toString().toLocaleLowerCase();
        setLoadingVal(true);
        queryOrder({ orderID: term, useCompany: useCoName })
          .then((res) => {
            setFoundList(res);
          })
          .finally(() => {
            setLoadingVal(false);
          });
      } else {
        setFoundList([]);
      }
    }, delay);
    return () => clearTimeout(timeoutId);
  }, [searchVal, useCoName, delay]);

  React.useEffect(() => {
    if (loading) {
      if (searchVal) {
        const term = searchVal.toString().toLocaleLowerCase();
        queryOrder({ orderID: term, useCompany: useCoName }).then((res) => {
          setFoundList(res);
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    }
  }, [loading, searchVal, useCoName]);

  const toggleStyle = {
    border: 0,
    borderRadius: 0,
  };

  return (
    <>
      <Stack
        direction="row"
        key="SearchArea"
        spacing={2}
        alignItems="center"
        sx={{
          bgcolor: "background.default",
          pr: 2,
          borderRadius: 5,
          border: (theme) => `1px solid ${theme.palette.divider}`,
          overflow: "hidden",
        }}
      >
        <Stack direction="row">
          <ToggleButtonGroup color="info" value={useCoName} sx={toggleStyle}>
            <ToggleButton
              value={true}
              onClick={() => setUseCoName(true)}
              sx={toggleStyle}
            >
              Company
            </ToggleButton>
            <ToggleButton
              value={false}
              onClick={() => setUseCoName(false)}
              sx={toggleStyle}
            >
              Order ID
            </ToggleButton>
          </ToggleButtonGroup>
          <TextField
            label={useCoName ? "Search Company Name" : "Search Order ID"}
            variant="filled"
            value={searchVal}
            color="secondary"
            onChange={(e) => setSearchVal(e.target.value)}
            sx={{
              "& .MuiFilledInput-root": {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              },
            }}
            InputProps={{
              endAdornment: (
                <>
                  {(loading || loadingVal) && (
                    <CircularProgress color="info" size={20} />
                  )}
                </>
              ),
            }}
          />
        </Stack>
        <Collapse in={searchVal} orientation="horizontal">
          <Tooltip title={"Reset Search"}>
            <IconButton
              aria-label="Reset"
              color="secondary"
              onClick={() => setDefault()}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Collapse>
        <Tooltip
          title={"Latest Orders"}
          onClick={() => {
            setUseCoName(false);
            setSearchVal("OR");
          }}
        >
          <IconButton color="info">
            <Today />
          </IconButton>
        </Tooltip>
      </Stack>
      <Collapse in={loading}>
        <CircularProgress size="1.5rem" />
      </Collapse>
      {foundList.length === 0 && searchVal && !loading && (
        <Typography variant="body2" color="primary">
          No order found matching that ID
        </Typography>
      )}
      <Collapse in={foundList.length > 0} sx={{ width: "100%" }}>
        <UniversalTable
          loading={loading}
          setLoading={setLoading}
          data={foundList}
          headers={headers}
          name="Add Order"
        />

        <Button
          variant="outlined"
          color="warning"
          sx={{ float: "right" }}
          onClick={() => setDefault()}
        >
          Close Search
        </Button>
      </Collapse>
    </>
  );
}

function ClientArea({
  orderIdList,
  setOrderIdList,
  setOrderList,
  setItems,
  setPoItems,
  setPendingItems,
  currency,
}) {
  const [show, setShow] = React.useState(false);
  const addOrder = (order, autoAdd) => {
    if (orderIdList.includes(order.orderID)) {
      toast.error("Order already added");
      return;
    }

    setOrderIdList((previous) => [...previous, order.orderID]);
    setOrderList((previous) => [...previous, order]);

    setItems((previous) => {
      return [...previous, { orderID: order.orderID, items: [] }];
    });

    setPoItems((previous) => {
      return [
        ...previous,
        {
          orderID: order.orderID,
          items: [],
          ...(order.isManual && {
            manualDetails: JSON.stringify({
              company: order.company_name,
              note: order.note,
            }),
          }),
        },
      ];
    });
    setPendingItems((previous) => [
      ...previous,
      { orderID: order.orderID, items: [] },
    ]);

    toast.info(`Order ${order.orderID} added`);
  };

  return (
    <Box sx={{ my: 1 }}>
      <Stack
        direction="column"
        spacing={2}
        alignItems="flex-start"
        sx={{ mb: 2 }}
      >
        <SearchOrder addOrder={addOrder} />
        <Button
          variant={show ? "outlined" : "contained"}
          color={show ? "warning" : "primary"}
          onClick={() => setShow((previous) => !previous)}
          startIcon={show ? <RemoveIcon /> : <Add />}
        >
          {show ? "Cancel Add details manually" : "Add details manually"}
        </Button>
        <Collapse in={show}>
          <ManualDetailsForm
            onClose={() => setShow(false)}
            addOrder={addOrder}
            currency={currency}
          />
        </Collapse>
      </Stack>
    </Box>
  );
}

function PosItemsArea({
  orders,
  setOrders,
  poItems,
  setPoItems,
  orderItems,
  setOrderItems,
  setOrderIdList,
  pendingItems,
  setPendingItems,
  currency,
  setExchangeRates,
  exchangeRates,
}) {
  if (orders.length === 0) {
    return (
      <>
        <Divider />
        <Typography variant="body1" sx={{ textAlign: "center", my: 2 }}>
          No Orders added
        </Typography>
        <Divider />
      </>
    );
  }
  return (
    <>
      <TransitionGroup style={{ width: "100%" }}>
        {orders.map((order, index) => (
          <Collapse key={order.orderID}>
            <OrderArea
              key={order.orderID}
              order={order}
              poItems={poItems}
              setPoItems={setPoItems}
              orderItems={
                orderItems.find((object) => object.orderID === order.orderID)
                  .items
              }
              setOrderItems={setOrderItems}
              setOrderIdList={setOrderIdList}
              setOrders={setOrders}
              pendingItems={
                pendingItems.find((object) => object.orderID === order.orderID)
                  .items
              }
              setPendingItems={setPendingItems}
              currency={currency}
              setExchangeRates={setExchangeRates}
              exchangeRates={exchangeRates}
            />
          </Collapse>
        ))}
      </TransitionGroup>
    </>
  );
}

function OrderArea({
  order,
  setOrders,
  poItems,
  setPoItems,
  orderItems,
  setOrderItems,
  setOrderIdList,
  pendingItems,
  setPendingItems,
  currency,
  setExchangeRates,
  exchangeRates,
}) {
  const [hide, setHide] = React.useState(true);

  // this effect runs only for orders created maually, it automatically adds
  // the order items and the pos items.
  React.useEffect(() => {
    if (order.isManual) {
      order.items.forEach((item) => {
        setOrderItems((previous) => {
          const orderItemsObject = previous.find(
            (object) => object.orderID === order.orderID
          );
          if (
            orderItemsObject.items.findIndex(
              (orderItem) => orderItem.id === item.id
            ) === -1
          ) {
            orderItemsObject.items.push({
              id: item.id,
              itemID: item.itemID,
              partNumber: item.partNumber,
              description: item.description,
              quantity: item.quantity,
              price: item.price,
              linkedItem: true,
              aquired: 0,
              pending: [],
              poItems: [],
              orderID: order.orderID,
            });
          }

          return [...previous];
        });
        setPoItems((previous) => {
          const poObject = previous.find(
            (object) => object.orderID === order.orderID
          );
          if (
            poObject.items.findIndex(
              (orderItem) => orderItem.id === item.id
            ) === -1
          ) {
            poObject.items.push({
              id: item.id,
              itemID: item.itemID,
              partNumber: item.partNumber,
              description: item.description,
              quantity: item.quantity,
              price: item.price,
              manual: {
                partNumber: item.partNumber,
                quantity: item.quantity,
                price: item.price,
                description: item.description,
              },
              deleteID: item.id,
              po_item_id: null,
            });
          }

          return [...previous];
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkAdded = (id) => {
    const isAdded = orderItems.findIndex((item) => item.id === id);
    return isAdded !== -1 ? true : false;
  };

  const addItem = (orderId, itemData) => {
    setOrderItems((previous) => {
      const itemList = previous.find(
        (object) => object.orderID === orderId
      ).items;

      if (itemList.indexOf(itemData) !== -1) return previous;

      itemData.linkedItem = true; //allows us to update the po holder values
      itemList.push(itemData);

      return [...previous];
    });
  };

  const removeOrder = (orderId) => {
    // clears the array containing the orderids
    setOrderIdList((previous) => previous.filter((id) => id !== orderId));
    // clears the array containing the orders
    setOrders((previous) =>
      previous.filter((order) => order.orderID !== orderId)
    );
    // clears the array containing the added original items
    setOrderItems((previous) =>
      previous.filter((order) => order.orderID !== orderId)
    );
    // clears the array containing the added pos items
    setPoItems((previous) =>
      previous.filter((order) => order.orderID !== orderId)
    );

    //clears pending items list
    setPendingItems((previous) =>
      previous.filter((order) => order.orderID !== orderId)
    );
  };

  return (
    <Paper variant="outlined" sx={{ mb: 3 }}>
      <Stack spacing={3}>
        <Paper sx={{ p: 3 }}>
          <Stack spacing={1}>
            <OrderSummary
              order={order}
              poItems={poItems}
              orderItems={orderItems}
              currency={currency}
              setExchangeRates={setExchangeRates}
              exchangeRates={exchangeRates}
              orderDelete={removeOrder}
            />

            <OrderCard
              details={order}
              addItem={addItem}
              checkAdded={checkAdded}
              orderItems={orderItems}
              poItems={poItems}
              setExchangeRates={setExchangeRates}
              exchangeRates={exchangeRates}
              poCurrency={currency}
            />
          </Stack>
        </Paper>

        <Box sx={{ px: 2 }}>
          <TransitionGroup style={{ width: "100%" }}>
            {orderItems.map((orderItem) => (
              <Collapse key={orderItem.id}>
                <PosItemArea
                  key={orderItem.id}
                  orderItem={orderItem}
                  setOrderItems={setOrderItems}
                  order={order}
                  posItems={poItems}
                  setPosItems={setPoItems}
                  addItem={addItem}
                  hide={hide}
                  pendingItems={pendingItems}
                  setPendingItems={setPendingItems}
                  poCurrency={currency}
                  setExchangeRates={setExchangeRates}
                  exchangeRates={exchangeRates}
                />
              </Collapse>
            ))}
          </TransitionGroup>
        </Box>
      </Stack>
      {orderItems.length !== 0 && (
        <>
          <Divider />
          <Button
            onClick={() => setHide((previous) => !previous)}
            startIcon={hide ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            variant={hide ? "text" : "outlined"}
            sx={{ m: 0 }}
            fullWidth
          >
            {hide ? "Hide Items" : "Show Items"}
          </Button>
        </>
      )}
    </Paper>
  );
}
